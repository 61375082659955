import styled from "styled-components"
import React, { FC } from "react"
import ImageUrl from "./bg.jpg"
import Theme from "theme/Theme"
import { ViewContextConsumer, ViewContextProvider } from "context/viewContext"

export const Layout = styled.div`
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  min-height: 100vh;
  overflow: hidden;
`

const Main = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${props => props.theme.media.md} {
    width: 50%;
  }

  animation: mainAnimation 1s ease-in-out;

  @keyframes mainAnimation {
    0% {
      opacity: 0;
      transform: scale(0.9);
    }

    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
`

const Image = styled.div<{ src: string }>`
  width: 50%;
  position: relative;
  background-image: url(${props => props.src});
  background-size: cover;
  background-position: center left;
  border-left: 12px solid ${props => props.theme.color.primary};
  animation: imageAnimation 1s ease-in-out;

  @keyframes imageAnimation {
    0% {
      opacity: 0;
      transform: translateX(10%) scale(1.02);
    }

    100% {
      opacity: 1;
      transform: translateX(0%) scale(1);
    }
  }
`

const LoginLayout: FC = ({ children }) => {
  return (
    <ViewContextProvider>
      <Theme>
        <ViewContextConsumer>
          {viewContext => (
            <Layout>
              <Main>{children}</Main>
              {(viewContext?.media.md ||
                viewContext?.media.lg ||
                viewContext?.media.xl) && <Image src={ImageUrl} />}
            </Layout>
          )}
        </ViewContextConsumer>
      </Theme>
    </ViewContextProvider>
  )
}

export default LoginLayout
