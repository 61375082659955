import React, { useState } from "react"
import styled from "styled-components"
import LoginLayout from "layouts/LoginLayout"
import { chevronBackOutline, lockClosedOutline } from "ionicons/icons"
import Button from "components/Button"
import Logo from "components/Logo"
import { useAuthToken } from "services/authToken"
import { navigate } from "gatsby"
import { useUserQuery } from "services/getUserData"
import Input from "components/Input"
import Link from "components/Link"
import Alert from "components/Alert/Alert"
import Spinner from "components/Spinner"
import { useAppApolloClient, useGuestApolloClient } from "services/client"
import { ApolloProvider, gql, useMutation } from "@apollo/client"
import Heading from "components/Heading"
import Widget, { WidgetSection } from "components/Widget"
import { useLocation } from "@reach/router"

const LinkWrapper = styled.div`
  margin-bottom: 18px;
`

const Form = styled.form`
  display: block;
  width: 300px;
  max-width: calc(100vw - 48px);
`

const StyledLogo = styled(Logo)`
  width: 160px;
  margin-bottom: 24px;
`

const LinksWrapper = styled.div`
  margin-top: 32px;
  text-align: center;
`

const RESET_PASSWORD_MUTATION = gql`
  mutation ResetPassword(
    $password: String!
    $passwordConfirmation: String!
    $code: String!
  ) {
    resetPassword(
      password: $password
      passwordConfirmation: $passwordConfirmation
      code: $code
    ) {
      user {
        username
      }
    }
  }
`

const LoginContent = () => {
  const [password, setPassword] = useState("")
  const [passwordRepeat, setPasswordRepeat] = useState("")
  const [authToken, removeAuthToken] = useAuthToken()
  const [errorMessage, setErrorMessage] = useState("")
  const [loading, setLoading] = useState(false)
  const location = useLocation()
  const urlParams = new URLSearchParams(location.search)
  const code = urlParams.get("code")
  const [resetPassword] = useMutation(RESET_PASSWORD_MUTATION)

  const userData = useUserQuery().data

  if (authToken && userData) {
    navigate("/konto")
  }

  const handlePasswordChange = (e: React.FormEvent<HTMLInputElement>) => {
    setErrorMessage("")
    setPassword(e.currentTarget.value)
  }

  const handlePasswordRepeatChange = (e: React.FormEvent<HTMLInputElement>) => {
    setErrorMessage("")
    setPasswordRepeat(e.currentTarget.value)
  }

  const handleSubmit = (e: React.MouseEvent) => {
    e.preventDefault()
    if (password.length < 6 || password.length > 16) {
      setErrorMessage(
        "Hasło musi zawierać przynajmniej 6 znaków i nie więcej niż 16 znaków."
      )
      return
    }

    if (password !== passwordRepeat) {
      setErrorMessage("Wprowadzone hasła nie są identyczne")
      return
    }
    setLoading(true)
    resetPassword({
      variables: {
        password: password,
        passwordConfirmation: passwordRepeat,
        code: code,
      },
    })
      .then(res => {
        if (res.data) {
          navigate(`/login?reset=true`)
        }
        setLoading(false)
      })
      .catch(err => {
        if (err.message.startsWith("Bad Request")) {
          setErrorMessage("Link do resetu hasła wygasł lub jest niepoprawny.")
        } else {
          setErrorMessage("Wystąpił błąd serwera. Proszę spróbować później.")
        }
        setLoading(false)
      })
  }

  return (
    <LoginLayout>
      <StyledLogo />
      <Widget white>
        <WidgetSection>
          <Heading center>Zresetuj hasło</Heading>
          <Form>
            <Input
              type={"password"}
              label={"Wprowadź nowe hasło"}
              icon={lockClosedOutline}
              value={password}
              onChange={handlePasswordChange}
              block
              required
            />
            <Input
              type={"password"}
              label={"Powtórz hasło"}
              icon={lockClosedOutline}
              value={passwordRepeat}
              onChange={handlePasswordRepeatChange}
              block
              required
            />

            <div style={{ marginTop: "32px" }}>
              <Button block onClick={handleSubmit}>
                {loading ? <Spinner size={24} /> : "Zapisz nowe hasło"}
              </Button>
            </div>
            <div style={{ marginTop: "16px" }}>
              <Alert isActive={!!errorMessage} error>
                {errorMessage}
              </Alert>
            </div>
          </Form>
        </WidgetSection>
      </Widget>
      <LinksWrapper>
        <LinkWrapper>
          <Link to={"/login"} icon={chevronBackOutline}>
            Powrót do logowania
          </Link>
        </LinkWrapper>
        <LinkWrapper>
          <Link to={"/"} icon={chevronBackOutline}>
            Powrót do strony głównej
          </Link>
        </LinkWrapper>
      </LinksWrapper>
    </LoginLayout>
  )
}

const Reset = () => {
  const client = useAppApolloClient()

  return (
    <>
      <ApolloProvider client={client}>
        <LoginContent />
      </ApolloProvider>
    </>
  )
}

export default Reset
